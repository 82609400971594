var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    ref: "sidebar",
    attrs: {
      title: _vm.edit ? _vm.$t("Editar Subgrupo") : _vm.$t("Novo Subgrupo"),
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      width: "400px",
    },
    on: { save: _vm.saveSubgroup, hidden: _vm.hideSidebar },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "FormulateForm",
                  { ref: "subgroupForm", attrs: { name: "subgroupForm" } },
                  [
                    _c("FormulateInput", {
                      staticClass: "required",
                      attrs: {
                        id: "product-subgroup-group-id",
                        type: "vue-select",
                        validation: "required",
                        label: _vm.$t("Grupo"),
                        options: _vm.getComboGroupsActive,
                      },
                      model: {
                        value: _vm.subgroupLocal.groupId,
                        callback: function ($$v) {
                          _vm.$set(_vm.subgroupLocal, "groupId", $$v)
                        },
                        expression: "subgroupLocal.groupId",
                      },
                    }),
                    _c("FormulateInput", {
                      staticClass: "required input-uppercase",
                      attrs: {
                        id: "product-subgroup-name",
                        type: "text",
                        label: _vm.$t("Nome"),
                        validation: "required",
                      },
                      model: {
                        value: _vm.subgroupLocal.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.subgroupLocal, "name", $$v)
                        },
                        expression: "subgroupLocal.name",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }