<template>
  <e-sidebar
    ref="sidebar"
    :title="edit ? $t('Editar Subgrupo') : $t('Novo Subgrupo')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="400px"
    @save="saveSubgroup"
    @hidden="hideSidebar"
  >
    <template #content>
      <div>
        <FormulateForm
          ref="subgroupForm"
          name="subgroupForm"
        >
          <FormulateInput
            id="product-subgroup-group-id"
            v-model="subgroupLocal.groupId"
            type="vue-select"
            class="required"
            validation="required"
            :label="$t('Grupo')"
            :options="getComboGroupsActive"
          />
          <FormulateInput
            id="product-subgroup-name"
            v-model="subgroupLocal.name"
            type="text"
            class="required input-uppercase"
            :label="$t('Nome')"
            validation="required"
          />
        </FormulateForm>
      </div>
    </template>
  </e-sidebar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ESidebar } from '@/views/components'
import { uploader } from '@/mixins'

const getInitialLocalSubgroup = () => ({
  id: null,
  name: '',
  groupId: null,
  active: null,
})

export default {
  components: {
    ESidebar,
  },

  mixins: [uploader],

  props: {
    subgroupId: {
      type: Number,
      default: null,
    },
    subgroup: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      localSubgroupId: null,
      subgroupLocal: getInitialLocalSubgroup(),
    }
  },
  computed: {
    ...mapGetters('pages/catalog/products', ['getComboGroupsActive']),
  },

  watch: {
    subgroup(val) {
      this.subgroupLocal = val
    },
    async subgroupId(val) {
      this.localSubgroupId = val
    },
    async localSubgroupId(val) {
      if (val) {
        try {
          this.fetching = true
          const { data: subgroupLocal } = await this.$http.get(
            `/api/product-subgroups/${this.localSubgroupId}`
          )
          this.subgroupLocal = subgroupLocal
          this.subgroupLocal.groupId = this.subgroupLocal.groupId.toString()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      } else {
        this.subgroupLocal = getInitialLocalSubgroup()
      }
    },
  },

  mounted() {
    this.getGroups()
  },

  methods: {
    ...mapActions('pages/catalog/products', { comboFetchDomainData: 'fetchDomainData' }),
    async getGroups() {
      try {
        this.fetching = true
        if (this.getComboGroupsActive?.length === 0) {
          await this.comboFetchDomainData('product-groups')
        }
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.fetching = false
      }
    },

    showCreate() {
      this.subgroupLocal = getInitialLocalSubgroup()
      this.edit = false
      this.showSidebar = true
    },

    async show({ id, item, groupId } = {}) {
      // debugger
      if (!id && !item?.id) {
        this.showCreate()
      } else {
        if (id) this.localSubgroupId = id
        if (item) this.subgroupLocal = item
        this.edit = true
        this.showSidebar = true
      }
      if (groupId) {
        this.subgroupLocal.groupId = groupId
      }
    },

    hideSidebar() {
      this.localSubgroupId = null
      this.subgroupLocal = getInitialLocalSubgroup()
      this.showSidebar = false
    },

    async saveSubgroup() {
      this.$refs.subgroupForm.showErrors()
      if (this.$refs.subgroupForm.isValid) {
        try {
          this.saving = true

          const body = {
            id: this.subgroupLocal.id,
            name: this.subgroupLocal.name,
            groupId: this.subgroupLocal.groupId,
            active: this.subgroupLocal.active,
          }
          const result = await this.$http({
            url: '/api/product-subgroups',
            method: this.edit ? 'PUT' : 'POST',
            data: body,
          })
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.showSidebar = false
          this.$emit('save', result.data)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },
  },
}
</script>

<style></style>
